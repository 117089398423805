import React from 'react';
import classes from './Pp.module.css';

function Pp() {
    return (
        <div className={classes.contain}>
          <h1>Data Privacy Policy</h1>
          <ol>
            <li>
              <h3>Introduction</h3>
              <ul>
                <li>
                  This Privacy Policy describes how deskafrika (referred to as "we", "us", or "our" in this policy) collects, uses, and discloses your personal information when you use our website (the "Website").
                </li>
              </ul>
            </li>
            <li>
              <h3>Information We Collect</h3>
              <ul>
                <li>
                  Email address: We collect your email address when you sign up for our newsletter, contact us through a form, or participate in other activities on our Website that require an email address.
                </li>
              </ul>
            </li>
            <li>
              <h3>How We Use Your Information</h3>
              <ul>
                <li>
                  To respond to your inquiries and requests.
                </li>
                <li>
                  To send you newsletters, promotional emails, and other relevant communication.
                </li>
              </ul>
            </li>
            <li>
              <h3>Your Choices</h3>
              <ul>
                <li>
                  Unsubscribe: You can unsubscribe from our email list at any time by clicking the "unsubscribe" link at the bottom of our emails.
                </li>
                <li>
                  Access and Update: You can request access to your information or request that we update your information by contacting us using the contact details provided on our website.
                </li>
              </ul>
            </li>
            <li>
              <h3>Data Retention</h3>
              <ul>
                <li>
                  We will retain your information for as long as necessary to fulfill the purposes described in this Privacy Policy unless a longer retention period is required or permitted by law.
                </li>
              </ul>
            </li>
            <li>
              <h3>Security</h3>
              <ul>
                <li>
                  We take reasonable steps to protect the information you provide to us from unauthorized access, disclosure, alteration, or destruction. However, no Internet transmission is completely secure, and we cannot guarantee the security of your information.
                </li>
              </ul>
            </li>
            <li>
              <h3>Children's Privacy</h3>
              <ul>
                <li>
                  Our Website is not directed to children under the age of 13. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and you believe that your child is under 13 and provided us with personal information, please contact us.
                </li>
              </ul>
            </li>
            <li>
              <h3>Changes to this Privacy Policy</h3>
              <ul>
                <li>
                  We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our Website.
                </li>
              </ul>
            </li>
          </ol>
          <div>
            <h3>Contact Us</h3>
            <p>
              If you have any questions about these Privacy Policy, please contact us through Email: <a href="mailto:deskafrika@gmail.com">deskafrika@gmail.com</a>.
            </p>
          </div>
        </div>
      );
}

export default Pp;