import React, { useState } from 'react';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import classes from './CreateEvent.module.css';


function CreateEvent() {
    const initialValues = {
        fName: '',
        ePhone: '',
        eName: '',
        eDate: '',
        eTime: '',
        eVenue: '',
        eFee: '',
        eDescription: '',
        acceptTerms: false 
    }; 

    const validationSchema = Yup.object().shape({
        fName: Yup.string().required("Required"),
        ePhone: Yup.string().required("Required"),
        eName: Yup.string().required("Required"),
        eDate: Yup.string().required("Required"),
        eTime: Yup.string().required("Required"),
        eVenue: Yup.string().required("Required"),
        eFee: Yup.string().required("Required"),
        eDescription: Yup.string().required("Required"),
        acceptTerms: Yup.boolean().oneOf([true], "Required")
    });


    const [isSubmitting, setIsSubmitting] = useState(false);

    const navigate = useNavigate();

    const onSubmit = async (data, { resetForm }) => {
        setIsSubmitting(true);
        try {
            await axios.post('https://server.goldbankafrica.com/events', { ...data });
            alert('Event created successfully!');
            resetForm();
            navigate('/');
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsSubmitting(false);
        } 
    };

    return (
        <div className={classes.createEvent}>
            <h1>Create event</h1>

            <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                <Form>
                    <div className={classes.inputFields}>
                        <div className={classes.formGroup}>
                            <ErrorMessage name="fName" component="div" className={classes.errorMessage} />
                            <Field name="fName" placeholder="Full name" className={classes.formControl} />
                        </div>

                        <div className={classes.formGroup}>
                            <ErrorMessage name="ePhone" component="div" className={classes.errorMessage} />
                            <Field name="ePhone" placeholder="Phone number" className={classes.formControl} />
                        </div>
                        
                        <div className={classes.formGroup}>
                            <ErrorMessage name="eName" component="div" className={classes.errorMessage} />
                            <Field name="eName" placeholder="Event name" className={classes.formControl} />
                        </div>

                        <div className={classes.formGroup}>
                            <ErrorMessage name="eDate" component="div" className={classes.errorMessage} />
                            <Field name="eDate" placeholder="Date" type="date" className={classes.formControl} />
                        </div>

                        <div className={classes.formGroup}>
                            <ErrorMessage name="eTime" component="div" className={classes.errorMessage} />
                            <Field name="eTime" placeholder="Time" className={classes.formControl} />
                        </div>

                        <div className={classes.formGroup}>
                            <ErrorMessage name="eVenue" component="div" className={classes.errorMessage} />
                            <Field name="eVenue" placeholder="Venue" className={classes.formControl} />
                        </div>

                        <div className={classes.formGroup}>
                            <ErrorMessage name="eFee" component="div" className={classes.errorMessage} />
                            <Field name="eFee" placeholder="Fee" className={classes.formControl} />
                        </div>

                        <div className={classes.formGroup}>
                            <ErrorMessage name="acceptTerms" component="div" className={classes.errorMessage} />
                            <label>
                                <Field type="checkbox" name="acceptTerms" className={classes.formBox} />
                                <p>I accept <Link className={classes.formLink} to="/terms" target="_blank">Terms and Conditions</Link> of this service.</p>
                            </label>
                        </div>
                    </div>

                    <div className={classes.formGroup}>
                        <ErrorMessage name="eDescription" component="div" className={classes.errorMessage} />
                        <Field name="eDescription" placeholder="Description" as="textarea" className={classes.desc} />
                    </div>

                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting ? 'Creating Event...' : 'Create Event'}
                    </button>
                </Form>
            </Formik>
        </div>
    );
}

export default CreateEvent;



