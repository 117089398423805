import React from 'react'
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Event from './Event';
import CreateEvent from './CreateEvent';
import Feed from './Feed';
import logo from "../assets/deskLogo.png";
import classes from "./Mtaa.module.css";
import Terms from './Terms';
import Pp from './Pp';


function Mtaa() {
  return (
    <div> 
        <Router>
            <div className={classes.nav}>
              <div className={classes.container}>
                <div className={classes.logo}>
                  <Link className={classes.link} to="/">
                    <img src={ logo } alt="logo" />
                    <h3>deskAfrika</h3> 
                  </Link>
                </div>
                <div className={classes.list}>
                  <ul>
                    <Link className={classes.link} to="/"><li>Home</li></Link>
                  </ul>
                </div>
              </div>
            </div>
            <Routes>
                <Route path='/' element={<Feed />} /> 
                <Route path='/createevent' element={<CreateEvent />} />
                <Route path='/terms' element={<Terms />} />
                <Route path='/policy' element={<Pp />} />
                <Route path='/events/:id' element={<Event />} />
            </Routes>
      </Router>
    </div> 
  )
}

export default Mtaa