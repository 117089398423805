import React from 'react';
import classes from './Terms.module.css';

function Terms() {
  return (
    <div className={classes.container}>
      <h1>Terms and Conditions</h1>
      <ol>
        <li>
          <h3>Ticket Sales</h3>
          <ul>
            <li>
              By selling tickets through our Website, you agree to pay 10% of the total ticket sale amount as a service fee.
            </li>
            <li>
              All ticket purchases are subject to availability and confirmation.
            </li>
          </ul>
        </li>
        <li>
          <h3>User Conduct</h3>
          <ul>
            <li>
              You agree to use the Website only for lawful purposes and in accordance with these Terms.
            </li>
            <li>
              You agree not to engage in any activity that interferes with or disrupts the operation of the Website.
            </li>
            <li>
              You agree not to attempt to gain unauthorized access to any part of the Website or any systems or networks connected to the Website.
            </li>
          </ul>
        </li>
        <li>
          <h3>Intellectual Property</h3>
          <ul>
            <li>
              The content on the Website, including text, graphics, logos, images, audio clips, and software, is owned or licensed by deskafrika and is protected by copyright, trademark, and other intellectual property laws.
            </li>
            <li>
              You may not reproduce, distribute, modify, or create derivative works of any content from the Website without our prior written consent.
            </li>
          </ul>
        </li>
        <li>
          <h3>Limitation of Liability</h3>
          <ul>
            <li>
              We are not liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or relating to your use of the Website.
            </li>
            <li>
              In no event shall our total liability to you for all damages exceed the amount paid by you, if any, for using the Website during the twelve (12) months prior to the claim.
            </li>
          </ul>
        </li>
        <li>
          <h3>Governing Law</h3>
          <ul>
            <li>
              These Terms shall be governed by and construed in accordance with the Laws of Kenya, without regard to its conflict of law provisions.
            </li>
            <li>
              Any legal action or proceeding arising out of or relating to these Terms shall be brought exclusively in the courts of Kenya.
            </li>
          </ul>
        </li>
        <li>
          <h3>Changes to Terms</h3>
          <ul>
            <li>
              We reserve the right to modify or replace these Terms at any time. The most current version of the Terms will be posted on the Website. Your continued use of the Website after any changes to these Terms constitutes your acceptance of the revised Terms.
            </li>
          </ul>
        </li>
      </ol>
      <div>
        <h3>Contact Us</h3>
        <p>
          If you have any questions about these Terms, please contact us through Email: <a href="mailto:deskafrika@gmail.com">deskafrika@gmail.com</a>.
        </p>
      </div>
    </div>
  );
}

export default Terms;
