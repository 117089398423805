import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import axios from 'axios';
import classes from './Event.module.css';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';
import { Link } from "react-router-dom";

function Event() {
    const { id } = useParams();
    const [event, setEvent] = useState(null);
    const [showCreateTiko, setShowCreateTiko] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [ticketCreated, setTicketCreated] = useState(false);

    useEffect(() => {
        axios.get(`https://server.goldbankafrica.com/events/byId/${id}`)
            .then(response => {
                setEvent(response.data); 
            })
            .catch(error => {
                console.error('Error fetching post:', error);
            }); 
    }, [id]); 

    const initialValues = {
        username: '',
        email: '',
        mpesaCode: '',
        numberOfTickets: ''
    }; 

    const validationSchema = Yup.object().shape({
        username: Yup.string().min(3).max(15).required("Required"),
        email: Yup.string().email().required("Required"),
        mpesaCode: Yup.string().required('Required'),
        numberOfTickets: Yup.number().min(1, 'Minimum 1 ticket').max(50, 'Maximum 50 tickets').required('Required')
    });

    const onSubmit = async (data, { resetForm }) => {
        setIsSubmitting(true);
        try {
            // Generate the specified number of tickets
            for (let i = 0; i < data.numberOfTickets; i++) {
                const randomCode = generateRandomCode(); 
                await axios.post('https://server.goldbankafrica.com/tickets', { ...data, ticket: randomCode, eventName: event.eName });
            }
            setTicketCreated(true);
            resetForm();
            setTimeout(() => {
                setTicketCreated(false);
                setShowCreateTiko(false); // Hiding the form after submission
            }, 10000);
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsSubmitting(false);
        }
    };
    

    const generateRandomCode = () => {
        const randomNumber = Math.floor(Math.random() * 999).toString().padStart(3, '0');
        const randomAlphabets = generateRandomAlphabets(2);
        return `DKN${randomNumber}${randomAlphabets}`;
    };

    const generateRandomAlphabets = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };

    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    }    

    return (
        <div className={classes.main}>
            {event ? (
                <div className={classes.container}>
                    <div className={classes.header}>
                        <div className={classes.wrap}>
                            <h1>{event.eName}</h1>
                            <h3>Date: {formatDate(event.eDate)}</h3>
                        </div>
                    </div>
                    <div className={classes.body}>
                        {showCreateTiko ? (
                            <div className={classes.createTicket}>
                                <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
                                    <Form>
                                        <div className={classes.instruction}>
                                            <ul>
                                                <h2>Lipa na MPesa</h2>
                                                <p>To enable ease of service please use correct Email and Mpesa code. Tickets will be sent to your email. The information you provide is protected by our <Link className={classes.policy} to="/policy" target="_blank">privacy policy</Link> .</p>
                                                <li>Paybill No.: <b>522 533</b></li>
                                                <li>Account No.: <b>7710495</b></li>
                                                <li>Amount: <b>{event.eFee}</b> per ticket</li>
                                            </ul>
                                        </div>
                                        
                                        <div className={classes.inputs}>
                                            <div className={classes.formGroup}>
                                                <ErrorMessage name="username" component="div" className={classes.errorMessage} />
                                                <Field name="username" placeholder="Username" className={classes.formControl} />
                                            </div>

                                            <div className={classes.formGroup}>
                                                <ErrorMessage name="email" component="div" className={classes.errorMessage} />
                                                <Field name="email" type="email" placeholder="Email" className={classes.formControl} />
                                            </div>

                                            <div className={classes.formGroup}>
                                                <ErrorMessage name="numberOfTickets" component="div" className={classes.errorMessage} />
                                                <div className={classes.nots}>
                                                    {/* <label name="numberOfTickets">Number of Tickets</label> */}
                                                    <Field name="numberOfTickets" type="number" placeholder="Number of Tickets" className={classes.formControl} />
                                                </div>
                                            </div>

                                            <div className={classes.formGroup}>
                                                <ErrorMessage name="mpesaCode" component="div" className={classes.errorMessage} />
                                                <Field name="mpesaCode" placeholder="M-Pesa Verification Code" className={classes.formControl} />
                                            </div>
                                        </div>

                                        <button className={classes.eventButton2} type="submit" disabled={isSubmitting}>
                                            {isSubmitting ? 'Generating Ticket...' : 'Buy Ticket'}
                                        </button>
                                    </Form>
                                </Formik>
                                {ticketCreated && <p>Ticket created successfully! Please check your email for the ticket(s).</p>}
                            </div>
                        ) : (
                            <div className={classes.eventDetails}>
                                <h3>Description</h3>
                                <p>{event.eDescription}</p>
                                <ul>
                                    <li><span>Date:</span> {formatDate(event.eDate)}</li>
                                    <li><span>Time:</span> {event.eTime}</li>
                                    <li><span>Venue:</span> {event.eVenue}</li>
                                    <li><span>Fee:</span> {event.eFee}</li>
                                </ul>
                                <button className={classes.eventButton1} onClick={() => setShowCreateTiko(true)} disabled={isSubmitting}>Buy Ticket</button>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
}

export default Event;

