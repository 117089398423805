import './App.css';
import Mtaa from './pages/Mtaa';

function App() {
  return (
    <div className="App">
      <div className="wrapper">
        <Mtaa />
      </div>
    </div>
  );
}

export default App;
