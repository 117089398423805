import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import classes from './Feed.module.css';

function Feed() {

  const [listOfEvents, setListOfEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate(); 

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await axios.get('https://server.goldbankafrica.com/events');
                setListOfEvents(response.data);
            } catch (error) {
                setError('An error occurred while fetching posts.');
            } finally {
                setLoading(false);
            }
        };

        fetchEvents();
    }, []);

  return (
    <div className={classes.main}>
        <div className={classes.container}>
            <h1>Events</h1>
            <p>Buy tickets to your favourite events by selecting the event below.</p>
            {loading ? (
                <div>Loading...</div>
            ) : error ? (
                <div>{error}</div>
            ) : (
                <div className={classes.events}>
                    {listOfEvents.map((value, key) => (
                        <div className={classes.event} key={value.id} onClick={() => navigate(`/events/${value.id}`)}>
                            <div className={classes.container2}>
                                <h2>{value.eName}</h2>
                                <p>{value.eVenue}</p>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    </div>
  )
} 

export default Feed;